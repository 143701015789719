





























































































import { AdminUsersTimelineRecord, ExtendedCommunity } from "@/includes/types/DashboardExtended/types";
import CoreUsersStatUser from "./components/CoreUsersStatUser.vue";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import Accordion from "piramis-base-components/src/components/Accordion/Accordion.vue";
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Vue, Component, Prop } from 'vue-property-decorator';
import { ApexOptions } from "apexcharts";
import VueApexCharts from "vue-apexcharts";

type FrontendAdminUsersTimelineRecord = Omit<AdminUsersTimelineRecord, 'users'> & {
  users: number
}

const enum ChartType {
  'message',
  'admin'
}

@Component({
  components: {
    CoreUsersStatUser,
    VueApexCharts,
    Accordion,
    HelpMessage,
    HighlightAnchor,
    PiTabs
  }
})
export default class AdminUsersStatistics extends Vue {
  @Prop() adminUsersTimeline!: ExtendedCommunity['admin_users_timeline']

  @Prop() adminUsersStat!: ExtendedCommunity['admin_users_stat']

  @Prop() from!: string

  @Prop() to!: string

  @Prop({ type: Boolean, required: false, default: true }) hasProfileButton!: boolean

  chartData: {
    [ChartType.message]: ApexChartSeries | null,
    [ChartType.admin]: ApexChartSeries | null,
  } = {
    [ChartType.message]: null,
    [ChartType.admin]: null,
  }

  currentChartKey: ChartType = ChartType.message

  get adminUsersStatSorted() {
    return this.adminUsersStat
      .filter(a => a.login !== CHATKEEPER_BOT_LOGIN)
      .slice()
      .sort((a, b) => {
        if (a.message_count > b.message_count) return -1
        if (a.message_count < b.message_count) return 1

        if (a.reply_count > b.reply_count) return -1
        if (a.reply_count < b.reply_count) return 1

        return 0
      })
  }

  get adminUsersStatSortedOtherLength() {
    return this.adminUsersStatSorted.slice(3).length
  }

  get chartOptions(): ApexOptions {
    return {
      dataLabels: {
        enabled: false,
      },
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales
      },
      colors: this.currentChartKey === ChartType.message ? [ 'rgba(var(--a-warning), 1)', 'rgba(var(--a-primary), 1)' ] : [ 'rgba(var(--a-success), 1)' ],
      legend: {
        showForSingleSeries: true,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
        },
        categories: this.fromTo.timeTicks,
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      },
    }
  }

  get fromTo() {
    return fromToTicks(this.from, this.to)
  }

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('dashboard_users_admin_statistics_chart_message').toString(),
        value: ChartType.message,
        icon: {
          name: 'message'
        }
      },
      {
        label: this.$t('dashboard_users_admin_statistics_chart_admin').toString(),
        value: ChartType.admin,
        icon: {
          name: 'people_alt'
        }
      },
    ]
  }

  tabClick(tab: ChartType) {
    this.currentChartKey = tab
  }

  async prepareFrontendTimeline(timeline: ExtendedCommunity['admin_users_timeline']) {
    return timeline.map<FrontendAdminUsersTimelineRecord>(value => {
      return {
        ...value,
        message_count: value.message_count - value.reply_count,
        users: value.users.length
      }
    })
  }

  gotoUsersAnalysis() {
    this.$store.commit('set_users', { value: this.adminUsersStatSorted.map(u => u.user_id), admin: true })

    this.$router.push({ name: 'statistics_users_analysis' })
  }

  created() {
    this.prepareFrontendTimeline(this.adminUsersTimeline)
      .then((frontendTimeline) => {
        const { calcSeries: messageSeries } = seriesByMetricInList(this.$i18n, frontendTimeline, [ 'reply_count', 'message_count' ], this.fromTo)
        const { calcSeries: adminSeries } = seriesByMetricInList(this.$i18n, frontendTimeline, [ 'users' ], this.fromTo)

        Promise.allSettled([ messageSeries(), adminSeries() ])
          .then(results => {
            const m = results[0]
            const u = results[1]

            if (m.status === "fulfilled") {
              this.$set(this.chartData, ChartType.message, m.value)
            }

            if (u.status === "fulfilled") {
              this.$set(this.chartData, ChartType.admin, u.value)
            }
          })
      })
  }
}
